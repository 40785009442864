<template>
  <div>
    <data-header/>
    <div class="content" ref="content">
      <audio ref="audio" src="@/assets/y1054.wav"/>
      <div class="content-left">
        <!--设备汇总-->
        <div class="equipment-summary">
          <div class="title">设备汇总</div>
          <div class="equipment-summary-all flex flex-column align-center color-65">
            <div class="margin-top-17 text-16">设备总数</div>
            <div class="flex align-baseline">
              <div class="text-32 text-bold">{{ bedDeviceStatistics.total }}</div>
              <div class=" margin-left-10 text-14">套</div>
            </div>
          </div>
          <div class="flex align-center">
            <div class="equipment-summary-list color-65E7BE text-14">
              <div class="">在用</div>
              <div class="text-24 text-bold">{{ bedDeviceStatistics.online }}</div>
              <div>套</div>
            </div>
            <div class="equipment-summary-list color-D7763C text-14">
              <div class="">离线</div>
              <div class="text-24 text-bold">{{ bedDeviceStatistics.offline }}</div>
              <div>套</div>
            </div>
            <div class="equipment-summary-list color-DF65E7 text-14">
              <div class="">到期</div>
              <div class="text-24 text-bold">{{ bedDeviceStatistics.expire }}</div>
              <div>套</div>
            </div>
          </div>
        </div>

        <!--设备用户年龄阶段分析-->
        <div class="analysis-equipment-users">
          <div class="title">设备用户年龄阶段分析</div>
          <div id="analysisEquipmentUsers"></div>
        </div>
        <!--地图定位分布-->
        <div class="map-positioning-distribution">
          <div class="title">地图定位分布</div>
          <div id="mapPositioningDistribution"></div>
        </div>
      </div>
      <div class="content-middle">
        <!--预警分析-->
        <div class="early-warning-analysis color-65">
          <div class="title">预警分析</div>
          <div class="flex align-center text-14">
            <div class="early-warning-analysis-1">
              <div class="flex align-center justify-center">
                <img width="26px" height="23px" src="@/assets/images/smartElderly/心率.png"/>
                <div class="margin-left-10">心率</div>
              </div>
              <div class="flex align-center justify-between padding-lr-30 margin-top-10">
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警数量</div>
                  <div>{{ alarmAnalysisLayout.beatRate.alarmCount }}</div>
                </div>
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警设备</div>
                  <div class="color-EC5354">{{ alarmAnalysisLayout.beatRate.deviceCount }}</div>
                </div>
              </div>
            </div>
            <div class="early-warning-analysis-1">
              <div class="flex align-center justify-center">
                <img width="23px" height="23px" src="@/assets/images/smartElderly/呼吸.png"/>
                <div class="margin-left-10">呼吸</div>
              </div>
              <div class="flex align-center justify-between padding-lr-30 margin-top-10">
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警数量</div>
                  <div>{{ alarmAnalysisLayout.breath.alarmCount }}</div>
                </div>
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警设备</div>
                  <div class="color-EC5354">{{ alarmAnalysisLayout.breath.deviceCount }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex align-center text-14 margin-top-10">
            <div class="early-warning-analysis-2">
              <div class="flex align-center justify-center">
                <img width="26px" height="20px" src="@/assets/images/smartElderly/打鼾.png"/>
                <div class="margin-left-10">打鼾</div>
              </div>
              <div class="flex align-center justify-between padding-lr-15 margin-top-10">
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警数量</div>
                  <div>{{ alarmAnalysisLayout.snoring.alarmCount }}</div>
                </div>
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警设备</div>
                  <div class="color-EC5354">{{ alarmAnalysisLayout.snoring.deviceCount }}</div>
                </div>
              </div>
            </div>
            <div class="early-warning-analysis-2">
              <div class="flex align-center justify-center">
                <img width="22px" height="14px" src="@/assets/images/smartElderly/体动.png"/>
                <div class="margin-left-10">体动</div>
              </div>
              <div class="flex align-center justify-between padding-lr-15 margin-top-10">
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警数量</div>
                  <div>{{ alarmAnalysisLayout.bodyMove.alarmCount }}</div>
                </div>
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警设备</div>
                  <div class="color-EC5354">{{ alarmAnalysisLayout.bodyMove.deviceCount }}</div>
                </div>
              </div>
            </div>
            <div class="early-warning-analysis-2">
              <div class="flex align-center justify-center">
                <img width="22px" height="17px" src="@/assets/images/smartElderly/离床.png"/>
                <div class="margin-left-10">离床</div>
              </div>
              <div class="flex align-center justify-between padding-lr-15 margin-top-10">
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警数量</div>
                  <div>{{ alarmAnalysisLayout.leaveBed.alarmCount }}</div>
                </div>
                <div class="flex flex-column align-center">
                  <div class="nowrap">预警设备</div>
                  <div class="color-EC5354">{{ alarmAnalysisLayout.leaveBed.deviceCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--今日睡眠-->
        <div class="sleep-today">
          <div class="title">今日睡眠 ({{ bedSleepStatistics.timeFrom }}～{{ bedSleepStatistics.timeTo }})</div>
          <div class="sleep-today-list flex flex-wrap text-14 text-bold color-65">
            <div v-for="(item,index) in bedSleepStatistics.countLayout"
                 class="flex flex-column justify-around align-center" :key="index">
              <div>{{ item.name }}</div>
              <div class="color-00CCCD">{{ item.count }}/{{ bedSleepStatistics.total }}</div>
            </div>
          </div>
        </div>
        <div class="flex align-start">
          <!--人员类别-->
          <div class="personnel-type">
            <div class="title">人员类别</div>
            <div id="personnelType"></div>
          </div>
          <!--服务汇总-->
          <div class="service-summary">
            <div class="title">服务汇总</div>
            <div class="service-summary-1 text-bold flex flex-column align-center justify-center">
              <div class="text-14 color-65">能力评估</div>
              <div class="margin-top-20 text-16 color-14CA65">{{ serviceLayout.assessed }}/{{
                  serviceLayout.assesTotal
                }}
              </div>
            </div>
            <div class="service-summary-1 text-bold flex align-center justify-around">
              <div class="flex flex-column align-center justify-center">
                <div class="text-14 color-65">总预警次数</div>
                <div class="margin-top-20 text-16 color-00E6E7">{{ serviceLayout.alarmTotal }}</div>
              </div>
              <div class="flex flex-column align-center justify-center">
                <div class="text-14 color-65">已处理次数</div>
                <div class="margin-top-20 text-16 color-14CA65">{{ serviceLayout.alarmHandled }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="early-warning-information" :class="{'early-warning-information-isCollapse':!isCollapse}">
          <div class="title">预警信息</div>
          <div class="early-warning-information-top flex align-center justify-end text-14 color-65">
            <div class="success-round margin-right-10"></div>
            <div class="margin-right-30">已处理</div>
            <div class="warn-round margin-right-10"></div>
            <div class="margin-right-35">未处理</div>
            <div class="processing" @click="showWarnInfo">一键处理</div>
          </div>
          <div class="early-warning-information-header flex align-center justify-around color-65 text-14"
               :class="{'early-warning-information-header-Collapse':!isCollapse}">
            <div>总数：{{ alarmStatisticsLayout.total }}</div>
            <div>今日：{{ alarmStatisticsLayout.today }}</div>
            <div>昨日：{{ alarmStatisticsLayout.yesterday }}</div>
            <div>同比：<span class="color-EC5354">{{ alarmStatisticsLayout.tb }}</span></div>
          </div>
          <div class="early-warning-information-table-header text-16 color-65"
               :class="{'early-warning-information-table-header-Collapse':!isCollapse}">
            <div class="width-83">用户</div>
            <div class="width-96">时间</div>
            <div class="width-182">预警</div>
            <div class="width-58">状态</div>
            <div class="width-116">操作</div>
          </div>
          <div class="early-warning-information-table">
            <div class="early-warning-information-table-header text-14 color-13C0FB"
                 :class="{'early-warning-information-table-header-Collapse':!isCollapse}" v-for="(item,index) in list"
                 :key="index"
                 style="height:4.95rem">
              <div class="width-83">{{ item.oldPersonName }}</div>
              <div class="width-96">{{ item.currDate }}</div>
              <div class="width-182">{{ item.alarmMessage }}</div>
              <div class="width-58">
                <div class="success-round " v-if="item.state === 2"></div>
                <div class="warn-round " v-if="item.state === 1"></div>
              </div>
              <div class="width-116 color-13EDE5 handle-list">
                <div @click="showWarnDetail(item.id)">查看</div>
                <div @click="showWarnInfoOne(item.id)" v-if="item.state === 1">处理</div>
              </div>
            </div>
          </div>
          <div style="text-align: center;margin-top: 5px">
            <el-pagination @current-change="handleCurrentChange" :page-size="15" layout="prev, pager, next"
                           :total="searchPageTotal"></el-pagination>
          </div>
        </div>
      </div>

      <!--预警信息处理-->
      <el-dialog z-index="100" title="预警信息处理" :visible.sync="warningInfoDialog" width="83.27rem">
        <div class="flex align-center color-65 text-16">
          <div class="width-112"><span v-if="warnId !== ''">*</span>处理人</div>
          <el-input placeholder="请输入内容" v-model="clickProcessingInfo.handleBy"/>
        </div>
        <div class="flex align-center color-65 text-16 margin-top-20">
          <div class="width-112">处理内容</div>
          <el-input placeholder="请输入内容" v-model="clickProcessingInfo.handleRemark"/>
        </div>
        <div class="flex flex-reverse margin-top-35">
          <div class="close-button" @click="warningInfoDialog = false">关闭</div>
          <div class="confirm-button margin-right-20" @click="clickProcessingSubmit">确定</div>
        </div>
      </el-dialog>

      <!--已处理预警信息详情-->
      <el-dialog title="已处理预警信息详情" :visible.sync="warningDetailDialog" width="83.27rem">
        <div class="text-16">
          <div class="flex align-center color-65">
            <div class="width-95">设备ID</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.deviceId"/>
            <div class="width-95 margin-left-20">用户姓名</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.oldPersonName"/>
          </div>
          <div class="flex align-center color-65 margin-top-20">
            <div class="width-95">机构名称</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.orgName"/>
            <div class="width-95 margin-left-20">是否已发送</div>
            <el-input placeholder="请输入内容" class="width-240 text-16"
                      v-model="warningDetail.alreadySend === true?'是':'否'"/>
          </div>
          <div class="flex align-center color-65 margin-top-20">
            <div class="width-95">采集项</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.cellectItem"/>
            <div class="width-95 margin-left-20">预警值来源</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.alarmSource"/>
          </div>
          <div class="flex align-center color-65 margin-top-20">
            <div class="width-95"><span>*</span>预警内容</div>
            <el-input placeholder="请输入内容" class="text-16" v-model="warningDetail.alarmMessage"/>
          </div>
          <div class="flex align-center color-65 margin-top-20">
            <div class="width-95">处理人</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.handleBy"/>
            <div class="width-95 margin-left-20">处理时间</div>
            <el-input placeholder="请输入内容" class="width-240 text-16" v-model="warningDetail.handleTime"/>
          </div>
          <div class="flex align-center color-65 margin-top-20">
            <div class="width-95">处理说明</div>
            <el-input placeholder="请输入内容" class="text-16" v-model="warningDetail.handleRemark"/>
          </div>
          <div class="flex flex-reverse margin-top-35">
            <div class="close-button" @click="warningDetailDialog = false">关闭</div>
          </div>
        </div>
      </el-dialog>

      <!--警告-->
      <el-dialog title="" :visible.sync="warnDialog" width="83.27rem">
        <div class="flex align-center justify-center flex-column">
          <img src="@/assets/images/smartElderly/icon-warning.png" width="120px" height="117px">
          <div class="margin-top-60 text-32 color-65">{{ warnMessage }}</div>
        </div>
        <div class="flex flex-reverse margin-top-35">
          <div class="close-button" @click="warnDialog = false">关闭</div>
        </div>
      </el-dialog>
      <!--提示-->
      <el-dialog title="" :visible.sync="dialog" width="83.27rem">
        <div class="flex align-center justify-center flex-column">
          <div class="margin-top-60 text-32 color-65">请打开电脑声音，保证能接收消息通知</div>
        </div>
        <div class="flex flex-reverse margin-top-35">
          <div class="close-button" @click="dialog = false">关闭</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dataHeader from "./header";

export default {
  components: { dataHeader },
  data() {
    return {
      dialog: true,
      audioUrl: 'https://hhs.huangshan.gov.cn/image-service/downloadImage?bucketId=GSP_PUBLIC&C-App-Id=GSP_APP_002&ObjNm=20210402113715238000010400181487fbe762dd18.mp3',
      warnDialog: false,// 警告
      warnMessage: "",// 警告信息
      warningInfoDialog: false, // 预警信息处理
      warningDetailDialog: false, // 已处理预警信息详情
      ageGroupLayout: [ // 设备用户年龄阶段分析
        { name: "60-69岁", count: 0 },
        { name: "70-79岁", count: 0 },
        { name: "80-89岁", count: 0 },
        { name: "90-99岁", count: 0 },
        { name: "100岁以上", count: 0 },
      ],
      alarmAnalysisLayout: { // 预警分析
        beatRate: {
          alarmCount: 0, // 预警数量
          deviceCount: 0, // 预警设备
        },
        breath: {
          alarmCount: 0, // 预警数量
          deviceCount: 0, // 预警设备
        },
        snoring: {
          alarmCount: 0, // 预警数量
          deviceCount: 0, // 预警设备
        },
        bodyMove: {
          alarmCount: 0, // 预警数量
          deviceCount: 0, // 预警设备
        },
        leaveBed: {
          alarmCount: 0, // 预警数量
          deviceCount: 0, // 预警设备
        }
      },
      sleepToday: [
        { name: '睡眠不足5小时', number: 1, all: 100 },
        { name: '睡眠时长6-8小时', number: 2, all: 100 },
        { name: '睡眠时长5-6小时', number: 3, all: 100 },
        { name: '睡眠时长超8小时', number: 4, all: 100 }],
      personTypeLayout: [
        { name: '空巢', count: '10' },
        { name: '独居', count: '20' },
        { name: '残疾人', count: '30' },
        { name: '寻访对象', count: '2' },
        { name: '入住养老机构', count: '33' },
        { name: '其他', count: '140' },
      ],
      alarmStatisticsLayout: { // 预警信息
        tb: 0,// 同比
        today: 0,//今日预警数
        total: 0,//总预警数
        yesterday: 0,// 昨日预警数
      },
      bedDeviceStatistics: { // 设备汇总
        expire: 0,//到期数量
        offline: 0,//
        online: 0,
        total: 0
      },
      serviceLayout: { // 服务汇总
        assesTotal: 111, // 能力评估总数
        assessed: 1,// 能力评估数
        alarmTotal: 200,// 总预警次数
        alarmHandled: 200,// 已处理次数
      },
      list: [],
      warningInfo: {
        name: "",//处理人
        content: "",// 处理内容
      },
      warningDetail: {},
      locationInfoLayout: [ // 地图定位分布
        {
          id: '',
          longitude: '108.94637',
          latitude: "34.34737",
          personType: '',
          personTypeName: '',
          typeName: "其他",
          name: "12312",
          sex: "女",
          address: ''
        }
      ],
      clickProcessingInfo: { // 一键处理信息
        handleBy: "",
        handleRemark: "",
      },
      searchPageNum: 1,
      searchListLoad: false,
      searchPageTotal: 0,
      socket: "",
      bedSleepStatistics: {
        countLayout: [],
        timeFrom: '',
        timeTo: '',
        total: ''
      },
      timeEr: '',
      warnId: '',
    }
  },
  mounted() {
    this.getData()
    this.getSearchList()
    let that = this;
    this.timeEr = setInterval(function () {
      that.getSearchList();
    }, 1000);
    this.webSocket()
  },
  beforeDestroy() {
    if (this.timeEr !== '') {
      clearInterval(this.timeEr)
    }
  },
  computed: {
    legendData() {
      return this.ageGroupLayout.map((item) => item.name)
    },
    seriesData() {
      const data = []
      this.ageGroupLayout.forEach((item) => {
        data.push({
          name: item.name,
          value: item.count,
        })
      })
      return data
    },
    personTypeLayoutXData() {
      return this.personTypeLayout.map((item) => item.name)
    },
    personTypeLayoutYData() {
      return this.personTypeLayout.map((item) => item.count)
    },
    "isCollapse": {
      get() {
        return this.$store.state.isCollapse;
      },
      set(newValue) {
        this.$store.state.isCollapse = newValue;
      }
    },
  },
  methods: {
    ...mapActions(['getDataFlash', 'getFlashList', 'getFlashDetail', 'clickProcessing', 'textToVol']),
    getData() {
      this.getDataFlash().then((res) => {
        if (res.code === 200) {
          this.alarmAnalysisLayout = res.data.alarmAnalysisLayout
          this.ageGroupLayout = res.data.ageGroupLayout
          this.alarmStatisticsLayout = res.data.alarmStatisticsLayout
          this.bedDeviceStatistics = res.data.bedDeviceStatistics
          this.locationInfoLayout = res.data.locationInfoLayout
          this.personTypeLayout = res.data.personTypeLayout
          this.serviceLayout = res.data.serviceLayout
          this.bedSleepStatistics = res.data.bedSleepStatistics
          this.getAnalysisEquipmentUsers()
          this.initMap(this.locationInfoLayout)
        }

        this.getPersonnelType()
      })
    },

    /**
     * 分页选择页数
     */
    handleCurrentChange(e) {
      this.searchPageNum = e
      this.getSearchList()
    },

    /**
     * 预警信息列表
     */
    getSearchList() {
      if (this.searchListLoad === true) {
        return
      }
      this.searchListLoad = true;
      this.getFlashList({
        pageNum: this.searchPageNum,
        pageSize: 15
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.dataList
          this.searchPageTotal = res.data.total
        }
        this.searchListLoad = false
      })
    },

    webSocket() {
      let that = this
      // const socketUrl = "ws://test.lianhuquyl.cn:8182/websocket/test_123456";
      const socketUrl = "wss://www.lianhuquyl.cn:8182/websocket/lianhu_123456";
      // const socketUrl = "wss://39.107.60.230:8182/websocket/tianshui_123456";
      // const socketUrl = "wss://121.40.217.235:8182/websocket/longting_123456";
      // const socketUrl = "wss://47.96.252.57:8182/websocket/tianshui_123456";
      this.socket = new WebSocket(socketUrl)
      this.socket.onopen = function (res) {
      }
      this.socket.onerror = function (res) {
      }
      this.socket.onmessage = function (msg) {
        const data = JSON.parse(msg.data)
        that.warnMessage = data.alarm
        that.$refs.audio.play();
        that.warnDialog = true
      }
    },

    /**
     * 设备用户年龄阶段分析
     */
    getAnalysisEquipmentUsers(list) {
      let myChart = this.$echarts.init(document.getElementById('analysisEquipmentUsers'))
      myChart.setOption({
        // 提示框
        tooltip: {
          trigger: 'item',
        },
        // 图例属性
        legend: {
          show: true,
          left: '20',
          top: '20',
          orient: 'vertical',
          itemGap: 15,
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            fontSize: 18,
            color: '#628EDB',
          },
          data: this.legendData,
        },
        // 圆心区域
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: ``,
          },
        },
        // 系列列表
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            right: -100,
            label: {
              show: true,
              fontSize: 16,
              formatter: '{d}%',
              color: '#65C6E7',
            },
            labelLine: {
              length1: 10,
              length2: 20,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 24,
                fontWeight: 'bold',
                lineStyle: {
                  color: '#65C6E7',
                }
              },
            },
            data: this.seriesData,
            color: ['#F29961', '#FFFF00', '#A682E6', '#E15D68', '#00CCCD'],
          },
        ],
      })
    },

    /**
     * 人员类别
     */
    getPersonnelType() {
      let myChart = this.$echarts.init(document.getElementById('personnelType'))
      myChart.setOption({
        title: {
          text: ''
        },
        tooltip: {
          show: false
        },
        grid: {
          left: 15,
          right: 10,
          top: 40,
          bottom: 15,
          containLabel: true
        },
        legend: {
          show: false
        },
        xAxis: [
          {
            type: 'category',
            data: this.personTypeLayoutXData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              interval: 0,
              textStyle: {
                color: '#65C6E7',
                fontSize: 12
              },
              formatter: function (value) {
                if (value.length === 4 || value.length === 5) {
                  const start = value.substring(0, 2);
                  const end = value.substring(2, value.length);
                  return start + "\n" + end;
                } else if (value.length === 6) {
                  const start = value.substring(0, 2);
                  const end = value.substring(3, 6);
                  return start + "\n" + end;
                } else {
                  return value;
                }
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#227081"
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            name: '(人)',
            nameLocation: "end",
            nameTextStyle: {
              color: '#65C6E7',
              fontSize: 13,
              padding: [0, 30, -5, 0]
            },
            type: 'value',
            min: 0,
            max: Math.max.apply(null, this.personTypeLayoutYData),
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#65C6E7',
                fontSize: 13
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#227081"
              }
            },
          }],
        color: ['#14C6CA', '#FFFF00', '#F29961', '#A682E6', '#E15D68', '#14CA65'],
        series: [
          {
            name: '人',
            type: 'bar',
            data: this.personTypeLayoutYData,
            barWidth: 10,
            itemStyle: {
              normal: {
                color: function (params) {
                  let colorList = ['#14C6CA', '#FFFF00', '#F29961', '#A682E6', '#E15D68', '#14CA65'];
                  return colorList[params.dataIndex];
                }
              }
            }
          }]
      })
    },

    /**
     * 一键处理
     */
    showWarnInfo() {
      this.clickProcessingInfo = {
        handleBy: '',
        handleRemark: ''
      }
      this.warnId = ''
      this.warningInfoDialog = true
    },
    /**
     * 单个处理
     */
    showWarnInfoOne(id) {
      this.clickProcessingInfo = {
        handleBy: '',
        handleRemark: ''
      }
      this.warnId = id
      this.warningInfoDialog = true
    },

    /**
     * 一键处理提交信息
     */
    clickProcessingSubmit() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = this.clickProcessingInfo;
      if (this.warnId !== '') {
        if (data.handleBy === "") {
          loading.close();
          this.$XModal.message({
            message: "请填写处理人",
            status: 'error',
          })
          return;
        }
        data.bedAlarmRecordIdList = [this.warnId]
      }
      this.clickProcessing(this.clickProcessingInfo).then((res) => {
        this.warningInfoDialog = false
        loading.close();
      })
    },

    /**
     * 现实预警详情
     */
    showWarnDetail(id) {
      this.getFlashDetail(id).then(res => {
        if (res.code === 200) {
          this.warningDetail = res.data
        }
        this.warningDetailDialog = true
      })
    },

    /**
     * 地图定位分布
     * @param _datalist
     */
    initMap(_datalist) {
      this.map = new BMapGL.Map("mapPositioningDistribution"); //创建一个地图实例
      // this.map.setMapStyleV2({
      //   styleId: "9e19b3c9f41d83f25b8c14757ec8dfac", //样式ID
      //   // styleJson: [
      //   //   {
      //   //     "featureType": "land",
      //   //     "elementType": "geometry",
      //   //     "stylers": {
      //   //       "visibility": "on",
      //   //       "color": "#091220ff"
      //   //     }
      //   //   }, {
      //   //     "featureType": "water",
      //   //     "elementType": "geometry",
      //   //     "stylers": {
      //   //       "visibility": "on",
      //   //       "color": "#113549ff"
      //   //     }
      //   //   }]
      // });
      this.map.setMapStyleV2({
        styleId: "9e19b3c9f41d83f25b8c14757ec8dfac", //样式ID
      });
      this.map.enableScrollWheelZoom(true); // 允许地图可被鼠标滚轮缩放
      _datalist.forEach((item) => {
        //设置中心点
        this.map.centerAndZoom(
            new BMapGL.Point(item.longitude, item.latitude),
            10
        ); //设置坐标点和展示级别
        //创建图标
        let myIcon = null;
        let datatType = item.personTypeName;
        switch (datatType) {
          case "独居":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman1.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          case "残疾人":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman2.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          case "空巢":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman7.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          case "巡访对象":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman4.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          case "入住养老机构":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman5.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          case "其他":
            myIcon = new BMapGL.Icon(
                require("@/assets/images/oldman6.png"),
                new BMapGL.Size(16, 40)
            );
            break;
          default:
            myIcon = new BMapGL.Icon(
                require("@/assets/images/dian.png"),
                new BMapGL.Size(12, 26)
            );
        }

        // 创建Marker标注，使用图标
        let point = new BMapGL.Point(item.longitude, item.latitude);
        let marker = new BMapGL.Marker(point, {
          icon: myIcon,
        });
        // 将标注添加到地图
        this.map.addOverlay(marker);
        let content = ` <div class="alarmDiv">
                        <p style="color:#0BE1E6">姓名：${item.name || "--"}</p>
                        <p style="color:#0BE1E6">性别：${item.sex || "--"}</p>
                        <p style="color:#0BE1E6">类型：${item.personTypeName || "--"}</p>
                        <div style="color:#0BE1E6;">地址：${item.address || "--"}</div>
                      </div>`;

        //文字标注
        var label = new BMapGL.Label(content, {
          // 创建文本标注
          position: point,
          offset: new BMapGL.Size(10, -16),
        });
        // 将标注添加到地图中
        marker.setLabel(label);
        label.setStyle({
          // 设置label的样式
          display: "none",
          color: "rgba(255,255,255,.5)",
          fontSize: "14px",
          border: "1px solid #3C62C0",
          background: "rgba(51,51,51,.7)",
          borderRadius: "4px",
          padding: "11px",
          width: "121px",
          whiteSpace: "inherit"
        });
        marker.addEventListener("mouseover", function () {
          label.setStyle({
            // 设置label的样式
            display: "block",
          });
        });
        marker.addEventListener("mouseout", function () {
          label.setStyle({
            // 设置label的样式
            display: "none",
          });
        });
      });
      this.map.addEventListener("tilesloaded", () => {
        <dv-loading>Loading...</dv-loading>;
      });
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: start;
  min-height: calc(100%);
  background-color: #191a23;
  padding-bottom: 28px;
}

.content-left {
  display: flex;
  flex-direction: column;
}

.content-middle {
  display: flex;
  flex-direction: column;
}

.content-right {
  display: flex;
  flex-direction: column;
}

.equipment-summary {
  width: 54.39rem;
  height: 42.19rem;
  background-image: url("../../../assets/images/smartElderly/设备汇总框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.54rem 0 0 1.54rem;
}

.title {
  margin-top: 2.64rem;
  margin-left: 6.59rem;
  color: #65C6E7;
  font-size: 1.65rem;
}

.equipment-summary-all {
  width: 42.3rem;
  height: 10.88rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
  margin-top: 5.27rem;
  margin-left: 6.59rem;
}

.equipment-summary-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.2rem 0;
  justify-content: space-around;
  margin-top: 1.43rem;
  width: 13.29rem;
  height: 10rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

.equipment-summary-list:first-child {
  margin-left: 6.59rem;
}

.equipment-summary-list:not(:first-child  ) {
  margin-left: 11px;
}

.analysis-equipment-users {
  width: 54.39rem;
  height: 35.93rem;
  background-image: url("../../../assets/images/smartElderly/设备用户年龄阶段分析框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.44rem 0 0 0.44rem;
}

#analysisEquipmentUsers {
  height: 26.4rem;
  margin-top: 1.54rem;
  width: 50.05rem;
  margin-left: 2.75rem;
}

#mapPositioningDistribution {
  width: 49.5rem;
  height: 24.53rem;
  margin-left: 2.97rem;
  margin-top: 2.64rem;
}

.map-positioning-distribution {
  width: 54.39rem;
  height: 34.94rem;
  background-image: url("../../../assets/images/smartElderly/地图定位分布框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.44rem 0 0 1.54rem;
}

.early-warning-analysis {
  width: 62.85rem;
  height: 39.22rem;
  background-image: url("../../../assets/images/smartElderly/预警分析-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 2.2rem 0 0 0.77rem;
}

.early-warning-analysis .title {
  margin: 5.5rem 0 0 5.5rem;
}

.early-warning-analysis-1 {
  margin-top: 2.75rem;
  padding-top: 1.43rem;
  width: 26.51rem;
  height: 9.46rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

.early-warning-analysis-1:first-child {
  margin-left: 4.29rem;
  margin-right: 1.21rem;
}

.early-warning-analysis-2 {
  margin-top: 1.43 erm;
  padding-top: 1.32rem;
  width: 17.27rem;
  height: 9.57rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

.early-warning-analysis-2:first-child {
  margin-left: 4.29rem;
}

.early-warning-analysis-2:not(:first-child) {
  margin-left: 1.21rem;
}


.sleep-today {
  width: 62.92rem;
  height: 34.65rem;
  background-image: url("../../../assets/images/smartElderly/今日睡眠-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.21rem 0 0 0.77rem;
}

.sleep-today .title {
  margin: 5.5rem 0 0 5.5rem;
}

.sleep-today-list {
  margin-left: 4.29rem;
  width: 55.88rem;
}

.sleep-today-list > div {
  margin-top: 1.32rem;
  margin-right: 1.21rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  width: 26.4rem;
  height: 6.71rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

.personnel-type {
  width: 32.12rem;
  height: 37.29rem;
  background-image: url("../../../assets/images/smartElderly/人员类别-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.21rem 0 0 0.77rem;
}

.personnel-type .title {
  margin: 4.84rem 0 0 5.5rem;
}

#personnelType {
  width: 30.03rem;
  height: 29.15rem;
  margin-left: 1.1rem;
}

.service-summary {
  width: 30.03rem;
  height: 37.29rem;
  background-image: url("../../../assets/images/smartElderly/服务汇总-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.21rem 0 0 0.77rem;
}

.service-summary .title {
  margin: 4.84rem 0 0 5.5rem;
}

.service-summary-1 {
  margin-top: 1.87rem;
  margin-left: 3.41rem;
  width: 23.21rem;
  height: 10.67rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

.early-warning-information {
  width: 60.83rem;
  height: 114.29rem;
  background-image: url("../../../assets/images/smartElderly/预警信息-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.54rem 0 0 0.88rem;
}

.early-warning-information-isCollapse {
  width: calc(60.83rem + 256px);
}

.early-warning-information .title {
  margin: 2.64rem 0 0 46.86rem;
}

.early-warning-information-top {
  margin-top: 4.18rem;
  margin-right: 4.51rem;
}

.success-round {
  width: 1.1rem;
  height: 1.1rem;
  background-color: #14CA65;
  border-radius: 50%;
}

.warn-round {
  width: 1.1rem;
  height: 1.1rem;
  background-color: #EC5354;
  border-radius: 50%;
}

.processing {
  width: 8.8rem;
  height: 2.64rem;
  line-height: 2.64rem;
  color: #B5FFF7;
  text-align: center;
  font-weight: bold;
  font-size: 1.54rem;
  background: #05A392;
  border-radius: 2px;
}

.early-warning-information-header {
  margin-left: 3.74rem;
  margin-top: 1.87rem;
  width: 52.58rem;
  height: 5.28rem;
  background: rgba(0, 45, 86, 0.5);
  border: 1px solid #3C62C0;
}

.early-warning-information-header-Collapse {
  width: calc(52.58rem + 256px);
}

.early-warning-information-table-header {
  height: 5.61rem;
  width: 52.58rem;
  margin-left: 3.74rem;
  border-bottom: 1px solid #004586;
  display: flex;
  align-items: center;
}

.early-warning-information-table-header-Collapse {
  width: calc(52.58rem + 256px);
}

.early-warning-information-table-header > div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.width-83 {
  width: 17.4%;
}

.width-96 {
  width: 20.1%;
}

.width-182 {
  width: 32%;
}

.width-58 {
  width: 12.1%;
}

.width-116 {
  width: 18.2%;
}

.early-warning-information-table {
  max-height: 80.96rem;
  overflow: hidden;
  overflow-y: auto;
}

/deep/ .btn-prev, /deep/ .btn-prev:disabled {
  background-color: rgba(255, 255, 255, 0);
  color: #3C62C0 !important;
}


/deep/ .btn-next, /deep/ button:disabled {
  background-color: rgba(255, 255, 255, 0);
  color: #3C62C0;
}

/deep/ .el-pager li {
  line-height: 3.41rem !important;
}

/deep/ .el-pager .number.active {
  width: 3.52rem;
  height: 3.52rem;
  background: #18C3E8;
  border-radius: 2px;
  color: #031326;
  font-size: 1.65rem;
  margin-right: 2.2rem;
  border: none;
}

/deep/ .el-pager .number, /deep/ .el-pager .more {
  width: 3.52rem;
  height: 3.52rem;
  background: rgba(0, 45, 86, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 2px;
  color: #13C0FB;
  font-size: 1.65rem;
  margin-right: 2.2rem;
}

/deep/ .el-dialog {
  border: 1px solid #3C62C0;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  background: #001B36;
}

/deep/ .el-dialog__title, /deep/ .el-dialog__headerbtn > .el-icon-close {
  color: #65C6E7 !important;
}

/deep/ .el-dialog .el-dialog__header {
  text-align: center;
}

/deep/ .el-dialog__body {
  border-bottom: 1px solid #3C62C0;
}

.width-112 {
  width: 12.32rem;
  text-align: center;
}

/deep/ input, /deep/ input:hover, /deep/ input:focus {
  background: #001428;
  border: 1px solid #3C62C0;
  border-radius: 8px;
  color: #65C6E7;
  height: 4.4rem;
  line-height: 4.4rem;
}

/deep/ input::-moz-placeholder {
  color: #316577;
  font-size: 1.76rem;
}

/deep/ input::-ms-input-placeholder {
  color: #316577;
  font-size: 1.76rem;
}

/deep/ input::-webkit-input-placeholder {
  color: #316577;
  font-size: 1.76rem;
}

.confirm-button {
  width: 9.79rem;
  height: 5.17rem;
  line-height: 5.17rem;
  background: #65C6E7;
  border: 1px solid #00246D;
  border-radius: 8px;
  text-align: center;
  font-size: 1.76rem;
  color: #000509;
}

.close-button {
  width: 9.79rem;
  height: 5.17rem;
  background: #001B36;
  border: 1px solid #3C62C0;
  border-radius: 8px;
  font-size: 1.76rem;
  color: #65C6E7;
  text-align: center;
  line-height: 5.17rem;
}

.width-240 {
  width: 26.4rem;
}

.width-95 {
  width: 10.45rem;
  text-align: right;
  padding-right: 1.87rem;
  flex: none;
}

.alarmDiv {
  width: 13.31rem;
  height: 15.95rem;
  background: rgba(0, 27, 54, 0.9);
  border: 1px solid #3C62C0;
  border-radius: 4px;
}

/deep/ .el-dialog__title {
  font-size: 1.98rem;
}

.handle-list {
  justify-content: space-around !important;
}

</style>
<style>
.el-main {
  border: none !important;
}
</style>
